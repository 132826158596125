import React, { useRef, useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { useSelector, useDispatch, connect } from "react-redux";
import cookie from "react-cookies";
import RestaurantAuth from "../../Services/auth.restaurants";

// component
const AddCartDrawer = dynamic(() => import("../AddCart/addCartDrawer"));
const MobileDrawer = dynamic(() => import("./mobiledrawer"));
// import MobileDrawer from "./mobiledrawer";
import useOutsideClick from "../useOutsideClick";
import { loginPageState, userIsLoggedState } from "../../redux/cart.slice";

// Images
import srBrand from "../../public/sr-logo.svg";

// icons
import Home from "@material-ui/icons/Home";
import Menu from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import StarIcon from "@material-ui/icons/Star";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import HistoryIcon from "@material-ui/icons/History";
import UndoIcon from "@material-ui/icons/Undo";

const Header = () => {
  const router = useRouter();
  const { query } = useRouter();
  const RouterDefine = router.pathname.split("/");
  const [showAddToCart, setAddToCart] = useState(false);
  const [showmobileDrawer, setmobileDrawer] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [showRouterData, setRouterData] = useState(false);
  const [showcart, setcart] = useState("");
  const [showRewardButton, setRewardButton] = useState(false);
  const [showMyAccount, setMyAccount] = useState(false);
  const dispatch = useDispatch();
  const [showLogoImage, setLogoImage] = useState("");

  const ref = useRef();
  const Myref = useRef();
  // Selecting cart from global state
  const cart = useSelector((state) => state.cart.value);
  const setCartDrawer = useSelector((state) => state.cart.stateLogin);
  const UserIsLogged = useSelector((state) => state.cart.userIsLogged);
  console.log(UserIsLogged, "setCartDrawer");
  //  console.log(cart,"cart")

  useEffect(() => {
    RestaurantAuth.getHomePageDetails().then((bannerRes) => {
      console.log(bannerRes.data.data, "bannerRes.data.data");

      if (Object.keys(bannerRes.data.data).length !== 0) {
        RestaurantAuth.getImageBy().then((ImagesRes) => {
          const LogoImage = ImagesRes.data.data.filter((data) => {
            return bannerRes.data.data.logo_image === data.id;
          });
          console.log(LogoImage, "LogoImage");
          if (LogoImage.length !== 0) {
            setLogoImage(LogoImage[0].filename_disk);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    console.log("add to cart calling setDrwaee");
    if (setCartDrawer !== undefined && showAddToCart !== true) {
      setAddToCart(setCartDrawer);
    } else {
      setAddToCart(!setCartDrawer);
    }
  }, [setCartDrawer]);

  useEffect(() => {
    setcart(cart);
  }, [cart]);

  useEffect(() => {
    const login = localStorage.getItem("guest_id");
    console.log(login, "logib");
    if (login !== null && UserIsLogged === true) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  }, [UserIsLogged]);

  useEffect(() => {
    console.log(RouterDefine[1], "router Define");
    if (RouterDefine[1] === "orderPayment") {
      setRouterData(true);
    } else {
      setRouterData(false);
    }
    if (RouterDefine[1] === "") {
      setRewardButton(false);
    } else if (RouterDefine[1] === "locationDetails") {
      setRewardButton(false);
    } else if (RouterDefine[1] === "search") {
      setRewardButton(false);
    } else {
      setRewardButton(true);
    }
  }, [RouterDefine]);

  const onHeaderImageClick = () => {
    console.log("djhsdsjd");
    setRouterData(false);
    router.push("/", undefined, { shallow: true });
  };

  const addtocartIcon = () => {
    console.log(showAddToCart, "showAddToCart");
    if (!showAddToCart) {
      document.body.classList.add("Add-cart-body");
    }
    setAddToCart(!showAddToCart);
  };
  const mobileDwawerclick = () => {
    document.body.classList.add("scroll-disable-body");
    setmobileDrawer(!showmobileDrawer);
  };

  const onClickReturn = (e) => {
    document.body.classList.remove("scroll-disable-body");
    setmobileDrawer(e);
    setAddToCart(e);
  };

  const logout = (e) => {
    e.preventDefault();
    dispatch(userIsLoggedState(false));
    dispatch(loginPageState(false));
    localStorage.removeItem("guest_id");
    localStorage.removeItem("spendgo_id");
    cookie.remove("guest_id");
    cookie.remove("spendgo_id");
    setIsLogged(false);

    window.location.href = "/";
  };

  // outsideclick function

  useOutsideClick(ref, (e) => {
    if (showAddToCart) {
      dispatch(loginPageState(false));
      setAddToCart(!showAddToCart);
    }
  });

  useOutsideClick(Myref, (e) => {
    if (showMyAccount) {
      setMyAccount(!showMyAccount);
    }
  });

  const onClickReward = () => {
    console.log(query, "test");
    const restaurantId = query.restaurantId;
    console.log(restaurantId, "Hello");
    router.push(`/rewards/${restaurantId}`, undefined, { shallow: true });
  };

  const onClickRecentOrder = () => {
    console.log("hello");
    setMyAccount(false);
    router.push("/recent-orders");
  };

  const onClickFavoriteOrders = () => {
    console.log("hello");
    setMyAccount(false);
    router.push("/favorite-order");
  };

  const onClickAccountSettings = () => {
    console.log("hello");
    setMyAccount(false);
    router.push("/view-account");
  };

  const onClickYourAccount = () => {
    console.log("hello");
    setMyAccount(true);
  };

  return (
    <>
      <div className="z-10 sticky top-0 p-2 lg:px-0 lg:py-3 bg-darkGreen">
        <div className="w-full container mx-auto">
          <nav className=" flex items-center ">
            {/* Home Button */}
            <div className="w-2/5">
              <button
                onClick={() => onHeaderImageClick()}
                className="hidden lg:block bg-darkGreen w-10 h-10  shadow-none hover:shadow-3xl transition duration-300"
              >
                <div className="">
                  <Home style={{ color: "#ffffff", fontSize: "30px" }} />
                </div>
              </button>
              <button
                onClick={mobileDwawerclick}
                className="block lg:hidden bg-darkGreen w-10 h-10  shadow-none hover:shadow-3xl transition duration-300"
              >
                <div className="">
                  <Menu style={{ color: "#ffffff", fontSize: "30px" }} />
                </div>
              </button>
            </div>
            <div className="w-1/5 text-center">
              {/* Branding */}
              <Link href="/">
                <a className="block text-center">
                  <div
                    style={{
                      position: "relative",
                      width: "110px",
                      height: "40px",
                      margin: "auto",
                    }}
                  >
                    {showLogoImage !== "" ? (
                      <img
                        alt="Homepage main banner"
                        src={`${process.env.NEXT_PUBLIC_DIRECTUS_API_URL}/assets/${showLogoImage}`}
                        layout="responsive"
                        objectfit="cover"
                        quality={100}
                      />
                    ) : (
                      <Image
                        alt="Homepage main banner test"
                        src={srBrand}
                        layout="responsive"
                        objectfit="contain"
                        quality={100}
                      />
                    )}
                  </div>
                </a>
              </Link>
            </div>
            <div className="w-2/5">
              {!showRouterData && (
                <div className="flex items-center justify-end">
                  {isLogged && showRewardButton && (
                    <button
                      onClick={onClickReward}
                      className="hidden lg:block lg:mr-2 xl:mr-4  h-10 py-2 px-1 bg-darkGreen  shadow-none hover:shadow-3xl transition duration-300 rounded-3"
                    >
                      <span className="flex items-center font-semibold text-white text-base">
                        <StarIcon
                          style={{
                            color: "#ffffff",
                            fontSize: "24px",
                            margin: "0px 4px ",
                          }}
                        />
                        <span className="ml-1 mr-1.5 lg:hidden xl:block">
                          View Rewards
                        </span>
                      </span>
                    </button>
                  )}

                  {isLogged ? (
                    <div className="relative">
                      <button
                        onClick={onClickYourAccount}
                        className="hidden lg:block lg:mr-2 xl:mr-4 h-10 py-2 px-1 bg-darkGreen  shadow-none hover:shadow-3xl transition duration-300 rounded-3"
                      >
                        <span className="flex items-center font-semibold text-white text-base">
                          <AccountCircle
                            style={{
                              color: "#ffffff",
                              fontSize: "24px",
                              margin: "0px 4px ",
                            }}
                          />
                          <span className="ml-1 mr-1.5 lg:hidden xl:block">
                            Your Account
                          </span>
                        </span>
                      </button>
                      {/* My account Drawer */}

                      <div
                        ref={Myref}
                        className={`sr-reward-nav absolute top-52 right-0 bg-white border sm:border-gray-9 shadow-4xl  ${
                          showMyAccount ? "open" : ""
                        }`}
                      >
                        <ul className="min-w-250">
                          <li>
                            <button
                              className="w-full text-left py-3 px-4 text-sm md:text-base text-black-1 hover:bg-neutral-100 "
                              onClick={onClickRecentOrder}
                            >
                              <HistoryIcon
                                style={{
                                  color: "#414141",
                                  fontSize: "24px",
                                  margin: "0px 4px",
                                }}
                              />
                              <span className="ml-3">Recent Orders</span>
                            </button>
                          </li>
                          <li>
                            <button
                              className="w-full text-left py-3 px-4 text-sm md:text-base text-black-1 hover:bg-neutral-100 "
                              onClick={onClickFavoriteOrders}
                            >
                              <FavoriteBorder
                                style={{
                                  color: "#414141",
                                  fontSize: "24px",
                                  margin: "0px 4px",
                                }}
                              />
                              <span className="ml-3"> Favorite Orders</span>
                            </button>
                          </li>
                          <li>
                            <button
                              className="w-full text-left py-3 px-4 text-sm md:text-base text-black-1 hover:bg-neutral-100 "
                              onClick={onClickAccountSettings}
                            >
                              <PersonOutlineOutlinedIcon
                                style={{
                                  color: "#414141",
                                  fontSize: "24px",
                                  margin: "0px 4px",
                                }}
                              />
                              <span className="ml-3"> Account Settings</span>
                            </button>
                          </li>
                          <li>
                            <button
                              className="w-full text-left py-3 px-4 text-sm md:text-base text-black-1 hover:bg-neutral-100 "
                              onClick={logout}
                            >
                              <UndoIcon
                                style={{
                                  color: "#414141",
                                  fontSize: "24px",
                                  margin: "0px 4px",
                                }}
                              />
                              <span className="ml-3">Sign Out</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <Link href="/customer/signIn">
                      <a className="hidden lg:block lg:mr-2 xl:mr-4 h-10 py-2 px-1 bg-darkGreen  shadow-none hover:shadow-3xl transition duration-300 rounded-3">
                        <span className="flex items-center font-semibold text-white text-base">
                          <span className="ml-1 mr-1.5"> Sign In</span>
                          <AccountCircle
                            style={{
                              color: "#ffffff",
                              fontSize: "24px",
                              margin: "0px 4px ",
                            }}
                          />
                        </span>
                      </a>
                    </Link>
                  )}
                  <button
                    type="button"
                    onClick={addtocartIcon}
                    className="block flex h-10 w-auto py-2 px-1 bg-darkGreen  shadow-none hover:shadow-3xl transition duration-300 rounded-3"
                  >
                    <ShoppingCart
                      style={{
                        color: "#ffffff",
                        fontSize: "24px",
                        margin: "0px 4px",
                      }}
                    />
                    {showcart > 0 ? (
                      <span className="text-white font-semibold ">
                        {showcart} Item
                      </span>
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              )}
            </div>
          </nav>
        </div>
      </div>

      {/* add to cart */}
      <div
        ref={ref}
        className={`fd-addtocart-hidden ${
          showAddToCart && "fd-addtocart-show"
        }`}
      >
        <div className="fd-addtocart-content">
          <AddCartDrawer onClickback={onClickReturn} />
        </div>
      </div>

      {/* mobile humber */}
      <div className="sr-mobile-drawer">
        <div
          className={`fd-mobile-hidden ${showmobileDrawer && "fd-mobile-show"}`}
        >
          <div className="fd-mobile-hidden-overlay absolute inset-0 bg-gray-6 z-10 pointer-events-none"></div>
          <div className="fd-mobile-content max-w-375">
            <MobileDrawer onClickback={onClickReturn} />
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(store) {
  const { cart } = store;
  console.log(cart, "dhfgutretyertretret");
  return { cart: cart && cart.value };
}

export default connect(mapStateToProps)(Header);
